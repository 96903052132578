import { Button, ButtonType } from 'components'
import { paths } from 'config/paths'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'

export default function NotFound() {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <div className="min-h-screen flex items-center justify-center dark:bg-black dark:text-white">
      <div className="flex items-start">
        <div className="font-bold text-4xl pr-8 pb-6 mr-8 border-r border-gray text-primary">
          404
        </div>
        <div>
          <div className="text-4xl font-bold">
            {t('notFound.Page not found')}
          </div>
          <div className="text-md text-gray-400">
            {t(
              'notFound.Please check the URL in the address bar and try again.'
            )}
          </div>
          <div className="flex items-center mt-10 gap-4">
            <Button
              buttonType={ButtonType.Secondary}
              onClick={() => window.open(paths.support.ticket, '_ blank')}
            >
              {t('notFound.Contact suport')}
            </Button>
            <Button onClick={() => router.push(paths.login)}>
              {t('notFound.Go back home')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
